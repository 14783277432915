import React, { useContext } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { LocalContext } from 'context';
import { SEO } from 'components';
import { navigate } from 'gatsby';
import QA1 from 'assets/images/docs/qanda1.png';
import QA2 from 'assets/images/docs/qanda2.png';
import QA3 from 'assets/images/docs/qanda3.png';

const QandA = () => {
  const { theme } = useContext(LocalContext);
  return (
    <>
      <SEO pageSpecificTitle="Help Hub | Q&A Documentation" />
      <MenuTab
        theme={theme}
        initial={{ y: -120 }}
        animate={{ y: 0 }}
        exit={{ y: -120 }}
        transition={{ delay: 0.75, duration: 0.75 }}>
        <MenuTitle
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 1.5, duration: 0.5 }}>
          <motion.button type="button" onClick={() => navigate(-1)}>
            <span>Help Hub</span>
          </motion.button>
          <span style={{ fontWeight: '500', paddingBottom: '0.125rem' }}>{'>'}</span>
          <span>Q&A</span>
        </MenuTitle>
      </MenuTab>
      <Wrapper
        theme={theme}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ delay: 0.5, duration: 1 }}>
        <Content>
          <h2>Q&A Guidelines</h2>
          <h3>
            As a moderator you have access to the Moderator&apos;s Control Panel from within the Q&A
            feature of the livestream event. Here you can directly reply to incoming questions from
            viewer&apos;s or submit viewer&apos;s questions for discussion during the livestream.
          </h3>
          <FigureWrapper>
            <Figure>
              <ImageWrapper>
                <Image src={QA1} alt="" />
                <ImageCaption>
                  <small>
                    The text field and buttons will be disabled until a question is available and
                    has been selected.
                  </small>
                </ImageCaption>
              </ImageWrapper>
            </Figure>
            <Figure>
              <ImageWrapper>
                <Image src={QA2} alt="" />
                <ImageCaption>
                  As questions come in they will be displayed in chronological order in the live
                  feed.
                </ImageCaption>
              </ImageWrapper>
            </Figure>
            <Figure>
              <ImageWrapper>
                <Image src={QA3} alt="" />
                <ImageCaption>
                  To select or deselect a question simply toggle the corresponding checkbox in the
                  live feed.
                  <br />
                  <br />
                  Choose to either respond directly using the text field provided or have a member
                  of the livestream event bring the answer up in discussion.
                </ImageCaption>
              </ImageWrapper>
            </Figure>
            <h4>Options for responding</h4>
            <Figure>
              <ImageWrapper>
                <iframe
                  title="vimeo-player"
                  src="https://player.vimeo.com/video/663292805?h=9cc467dff2&badge=0&player_id=0&app_id=58479&autoplay=1&loop=1&autopause=0"
                  frameBorder="0"
                  width="100%"
                  height="261"
                  allow="autoplay"
                />
                <ImageCaption>
                  There are two options for responding to viewer&apos;s questions. <br />
                  <br />
                  <br />
                  <strong>Direct response</strong>
                  <br />
                  <br />
                  Respond directly to a viewer&apos;s question by selecting the question, typing the
                  answer in the text field and selecting <strong>SUBMIT ANSWER</strong>. The
                  response will appear underneath the selected question.
                  <br />
                  <br />
                  <br />
                  <strong>Response from member/s of livestream</strong>
                  <br />
                  <br />
                  To submit a viewer&apos;s question for discussion during the livestream event.
                  Select the question and select <strong>ANSWER LIVE</strong>. The question will
                  appear in the livestream for all viewers and participants to see. The question may
                  then be answered by a member or members of the livestream event. To stop showing a
                  viewer&apos;s question select <strong>STOP SHOWING</strong>
                </ImageCaption>
              </ImageWrapper>
            </Figure>
            <h4>Prioritising questions</h4>
            <Figure>
              <ImageWrapper>
                <iframe
                  title="vimeo-player"
                  src="https://player.vimeo.com/video/663292805?h=9cc467dff2?autoplay=1&loop=1&autopause=0"
                  frameBorder="0"
                  width="100%"
                  height="261"
                  allow="autoplay"
                />
                <ImageCaption>
                  A prioritised question always appears at the top of the feed. Prioritising can
                  helpful for keeping track of things, allowing you to filter through incoming
                  questions.
                  <br />
                  <br />
                  To prioritise a question select <strong>☆ Prioritise</strong>. Select{' '}
                  <strong>☆ Unprioritise</strong> to remove a question from the top of the feed and
                  place it back in the timeline.
                </ImageCaption>
              </ImageWrapper>
            </Figure>
          </FigureWrapper>
        </Content>
      </Wrapper>
    </>
  );
};

const Wrapper = styled(motion.div)`
  align-items: center;
  color: ${({ theme }) => theme.primary};
  display: flex;
  flex-direction: column;
  gap: 3rem;
  grid-column: 1 / 7;
  justify-content: center;
  margin: 9rem 0 2rem 0;
  width: 100%;
  @media only screen and (min-width: 1150px) {
    grid-column: 1 / 13;
    margin: 10rem 0 4rem 0;
  }
`;

const MenuTab = styled(motion.div)`
  align-items: center;
  background: ${({ theme }) => theme.primary};
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  left: 0;
  max-height: 60px;
  min-height: 60px;
  position: absolute;
  top: 3.75rem;
  width: 100%;
  span {
    color: #ffffff;
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 1.5rem;
    padding: 0 1rem;
  }
  button {
    background: transparent;
    cursor: pointer;
    span {
      font-weight: 500;
    }
  }
  @media only screen and (min-width: 1150px) {
    grid-template-columns: repeat(12, 1fr);
    span {
      padding: 0;
      &:before {
        background: #ffffff;
        content: '';
        bottom: -2px;
        height: 2px;
        left: 0;
        position: absolute;
        width: 100%;
      }
    }
  }
`;

const MenuTitle = styled(motion.div)`
  align-items: center;
  display: flex;
  gap: 0.5rem;
  grid-column: 1 / 6;
  @media only screen and (min-width: 1150px) {
    grid-column: 2 / 5;
  }
`;

const Content = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  h2 {
    margin: 1rem;
  }
  h3 {
    font-weight: normal;
    text-align: center;
    width: 50%;
  }
`;

const Image = styled.img`
  width: 100%;
`;

const ImageWrapper = styled.div`
  padding: 1rem;
`;

const ImageCaption = styled.figcaption`
  padding: 1rem 0 2rem 0;
  width: 100%;
`;

const Figure = styled.figure`
  background-color: #f9f9f9;
  width: 60vw;
  @media only screen and (max-width: 1024px) {
    width: 100%;
  }
`;

const FigureWrapper = styled.div`
  margin: 1rem;
  figure {
    margin: 2rem 0;
  }
  @media only screen and (min-width: 1024px) {
    margin: 3rem;
  }
`;

export default QandA;
